@font-face {
    font-family: "p6-icon-font";
    src: url("p6-icon-font/p6-icon-font.eot");
    src: url("p6-icon-font/p6-icon-font.eot#iefix") format("embedded-opentype"),
        url("p6-icon-font/p6-icon-font.ttf") format("truetype"), url("p6-icon-font/p6-icon-font.woff") format("woff"),
        url("p6-icon-font/p6-icon-font.svg#p6-icon-font") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="p6-icon-"],
[class*=" p6-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "p6-icon-font" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.p6-icon-reports-group:before {
    content: "\e900";
}
.p6-icon-running-time:before {
    content: "\e901";
}
.p6-icon-test:before {
    content: "\e902";
}
.p6-icon-pin:before {
    content: "\e903";
}
.p6-icon-info:before {
    content: "\e904";
}
.p6-icon-paused:before {
    content: "\e905";
}
.p6-icon-folder:before {
    content: "\e906";
}
.p6-icon-history:before {
    content: "\e907";
}
.p6-icon-qr-scan:before {
    content: "\e908";
}
.p6-icon-camera:before {
    content: "\e909";
}
.p6-icon-reprofile:before {
    content: "\e90a";
}
.p6-icon-apple:before {
    content: "\e90b";
}
.p6-icon-google:before {
    content: "\e90c";
}
.p6-icon-games:before {
    content: "\e90d";
}
.p6-icon-imprint:before {
    content: "\e90e";
}
.p6-icon-play:before {
    content: "\e90f";
}
.p6-icon-stop:before {
    content: "\e910";
}
.p6-icon-speech-error:before {
    content: "\e911";
}
.p6-icon-no-internet:before {
    content: "\e912";
}
.p6-icon-alarm-clock:before {
    content: "\e913";
}
.p6-icon-chevron-down:before {
    content: "\e914";
}
.p6-icon-chevron-up:before {
    content: "\e915";
}
.p6-icon-cloud:before {
    content: "\e916";
}
.p6-icon-counter:before {
    content: "\e917";
}
.p6-icon-file:before {
    content: "\e918";
}
.p6-icon-finish:before {
    content: "\e919";
}
.p6-icon-heart:before {
    content: "\e91a";
}
.p6-icon-pic:before {
    content: "\e91b";
}
.p6-icon-plus:before {
    content: "\e91c";
}
.p6-icon-select-all:before {
    content: "\e91d";
}
.p6-icon-skip:before {
    content: "\e91e";
}
.p6-icon-sleep:before {
    content: "\e91f";
}
.p6-icon-sliders:before {
    content: "\e920";
}
.p6-icon-thumb-up:before {
    content: "\e921";
}
.p6-icon-unconfirmed:before {
    content: "\e922";
}
.p6-icon-uppercase:before {
    content: "\e923";
}
.p6-icon-database:before {
    content: "\e924";
}
.p6-icon-drag:before {
    content: "\e925";
}
.p6-icon-eye-closed:before {
    content: "\e926";
}
.p6-icon-filter:before {
    content: "\e927";
}
.p6-icon-in-use:before {
    content: "\e928";
}
.p6-icon-magic-wand:before {
    content: "\e929";
}
.p6-icon-pin-off:before {
    content: "\e92a";
}
.p6-icon-preview:before {
    content: "\e92b";
}
.p6-icon-random:before {
    content: "\e92c";
}
.p6-icon-scan:before {
    content: "\e92d";
}
.p6-icon-send:before {
    content: "\e92e";
}
.p6-icon-vocabulary-list:before {
    content: "\e92f";
}
.p6-icon-vocabulary-list-unset:before {
    content: "\e930";
}
.p6-icon-wishlist:before {
    content: "\e931";
}
.p6-icon-arrows-right-left:before {
    content: "\e932";
}
.p6-icon-calendar-edit:before {
    content: "\e933";
}
.p6-icon-cards-2:before {
    content: "\e934";
}
.p6-icon-challenge:before {
    content: "\e935";
}
.p6-icon-challenge-award:before {
    content: "\e936";
}
.p6-icon-chevron-left:before {
    content: "\e937";
}
.p6-icon-chevron-right:before {
    content: "\e938";
}
.p6-icon-clock:before {
    content: "\e939";
}
.p6-icon-discount:before {
    content: "\e93a";
}
.p6-icon-discount-group:before {
    content: "\e93b";
}
.p6-icon-download:before {
    content: "\e93c";
}
.p6-icon-duplicate:before {
    content: "\e93d";
}
.p6-icon-eye:before {
    content: "\e93e";
}
.p6-icon-family:before {
    content: "\e93f";
}
.p6-icon-feedback-2:before {
    content: "\e940";
}
.p6-icon-flowchart:before {
    content: "\e941";
}
.p6-icon-genius:before {
    content: "\e942";
}
.p6-icon-globe:before {
    content: "\e943";
}
.p6-icon-hand:before {
    content: "\e944";
}
.p6-icon-limit:before {
    content: "\e945";
}
.p6-icon-speech:before {
    content: "\e946";
}
.p6-icon-stuff:before {
    content: "\e947";
}
.p6-icon-switch:before {
    content: "\e948";
}
.p6-icon-switch-set:before {
    content: "\e949";
}
.p6-icon-task:before {
    content: "\e94a";
}
.p6-icon-translate:before {
    content: "\e94b";
}
.p6-icon-translate-set:before {
    content: "\e94c";
}
.p6-icon-in-10-false:before {
    content: "\e94d";
}
.p6-icon-alert:before {
    content: "\e94e";
}
.p6-icon-avatar:before {
    content: "\e94f";
}
.p6-icon-calendar-check:before {
    content: "\e950";
}
.p6-icon-calendar-cross:before {
    content: "\e951";
}
.p6-icon-cards:before {
    content: "\e952";
}
.p6-icon-change-avatar:before {
    content: "\e953";
}
.p6-icon-compass:before {
    content: "\e954";
}
.p6-icon-cube:before {
    content: "\e955";
}
.p6-icon-data-privacy-best:before {
    content: "\e956";
}
.p6-icon-edit-avatar:before {
    content: "\e957";
}
.p6-icon-exercise-failed:before {
    content: "\e958";
}
.p6-icon-exercise-ok:before {
    content: "\e959";
}
.p6-icon-login:before {
    content: "\e95a";
}
.p6-icon-logout-Kopie-32:before {
    content: "\e95b";
}
.p6-icon-logout-Kopie-35:before {
    content: "\e95c";
}
.p6-icon-logout-Kopie-36:before {
    content: "\e95d";
}
.p6-icon-my-school:before {
    content: "\e95e";
}
.p6-icon-my-school-set:before {
    content: "\e95f";
}
.p6-icon-notification:before {
    content: "\e960";
}
.p6-icon-overview:before {
    content: "\e961";
}
.p6-icon-p6:before {
    content: "\e962";
}
.p6-icon-school_building:before {
    content: "\e963";
}
.p6-icon-school_building_2:before {
    content: "\e964";
}
.p6-icon-school-building:before {
    content: "\e965";
}
.p6-icon-sent:before {
    content: "\e966";
}
.p6-icon-share-ios:before {
    content: "\e967";
}
.p6-icon-star-full:before {
    content: "\e968";
}
.p6-icon-stop-watch:before {
    content: "\e969";
}
.p6-icon-undone:before {
    content: "\e96a";
}
.p6-icon-first-steps:before {
    content: "\e96b";
}
.p6-icon-user-unconfirmed:before {
    content: "\e96c";
}
.p6-icon-flag:before {
    content: "\e96d";
}
.p6-icon-leaderboard:before {
    content: "\e96e";
}
.p6-icon-placement:before {
    content: "\e96f";
}
.p6-icon-achievement-task:before {
    content: "\e970";
}
.p6-icon-darkmode:before {
    content: "\e971";
}
.p6-icon-participants:before {
    content: "\e972";
}
.p6-icon-phase-up-down:before {
    content: "\e973";
}
.p6-icon-data-protection-awaiting:before {
    content: "\e974";
}
.p6-icon-data-protection-on:before {
    content: "\e975";
}
.p6-icon-annotation-alt:before {
    content: "\e976";
}
.p6-icon-data-protection-none:before {
    content: "\e977";
}
.p6-icon-child-unlock:before {
    content: "\e978";
}
.p6-icon-child-lock:before {
    content: "\e979";
}
.p6-icon-close:before {
    content: "\e97a";
}
.p6-icon-correct:before {
    content: "\e97b";
}
.p6-icon-cup-learned:before {
    content: "\e97c";
}
.p6-icon-mail:before {
    content: "\e97d";
}
.p6-icon-wrong:before {
    content: "\e97e";
}
.p6-icon-context-menu:before {
    content: "\e97f";
}
.p6-icon-special-character:before {
    content: "\e980";
}
.p6-icon-arrow-both:before {
    content: "\e981";
}
.p6-icon-arrow-left:before {
    content: "\e982";
}
.p6-icon-arrow-right:before {
    content: "\e983";
}
.p6-icon-check-answer:before {
    content: "\e984";
}
.p6-icon-leaderboard-nav:before {
    content: "\e985";
}
.p6-icon-speaker:before {
    content: "\e986";
}
.p6-icon-sync:before {
    content: "\e987";
}
.p6-icon-arrow-back-mobile:before {
    content: "\e988";
}
.p6-icon-audio:before {
    content: "\e989";
}
.p6-icon-calendar-day:before {
    content: "\e98a";
}
.p6-icon-help:before {
    content: "\e98b";
}
.p6-icon-settings-alt:before {
    content: "\e98c";
}
.p6-icon-bulb:before {
    content: "\e98d";
}
.p6-icon-documents:before {
    content: "\e98e";
}
.p6-icon-edit:before {
    content: "\e98f";
}
.p6-icon-lock:before {
    content: "\e990";
}
.p6-icon-logout:before {
    content: "\e991";
}
.p6-icon-mic:before {
    content: "\e992";
}
.p6-icon-reset:before {
    content: "\e993";
}
.p6-icon-search-book:before {
    content: "\e994";
}
.p6-icon-star-minus:before {
    content: "\e995";
}
.p6-icon-star-plus:before {
    content: "\e996";
}
.p6-icon-unlock:before {
    content: "\e997";
}
.p6-icon-reset-alt:before {
    content: "\e998";
}
.p6-icon-trash:before {
    content: "\e999";
}
.p6-icon-add:before {
    content: "\e99a";
}
.p6-icon-menu:before {
    content: "\e99b";
}
.p6-icon-share:before {
    content: "\e99c";
}
.p6-icon-add-to-library:before {
    content: "\e99d";
}
.p6-icon-feedback:before {
    content: "\e99e";
}
.p6-icon-activation:before {
    content: "\e99f";
}
.p6-icon-annotation:before {
    content: "\e9a0";
}
.p6-icon-rocket:before {
    content: "\e9a1";
}
.p6-icon-appearance:before {
    content: "\e9a2";
}
.p6-icon-cart:before {
    content: "\e9a3";
}
.p6-icon-clipboard:before {
    content: "\e9a4";
}
.p6-icon-dictionary:before {
    content: "\e9a5";
}
.p6-icon-group:before {
    content: "\e9a6";
}
.p6-icon-house:before {
    content: "\e9a7";
}
.p6-icon-learn:before {
    content: "\e9a8";
}
.p6-icon-library:before {
    content: "\e9a9";
}
.p6-icon-no-star:before {
    content: "\e9aa";
}
.p6-icon-one-star:before {
    content: "\e9ab";
}
.p6-icon-reports:before {
    content: "\e9ac";
}
.p6-icon-school:before {
    content: "\e9ad";
}
.p6-icon-search:before {
    content: "\e9ae";
}
.p6-icon-settings:before {
    content: "\e9af";
}
.p6-icon-three-stars:before {
    content: "\e9b0";
}
.p6-icon-two-stars:before {
    content: "\e9b1";
}
.p6-icon-user:before {
    content: "\e9b2";
}
.p6-icon-user-avatar:before {
    content: "\e9b3";
}
.p6-icon-cup:before {
    content: "\e9b4";
}
.p6-icon-keys:before {
    content: "\e9b5";
}
.p6-icon-keys-off:before {
    content: "\e9b6";
}
.p6-icon-paint:before {
    content: "\e9b7";
}
.p6-icon-refresh:before {
    content: "\e9b8";
}
.p6-icon-reports-add-user:before {
    content: "\e9b9";
}
.p6-icon-reports-family:before {
    content: "\e9ba";
}
