* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    font-size: 18px;
}

p,
h1,
h2,
h3,
span,
select,
textarea {
    margin: 0;
    font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html,
body,
#root {
    height: 100%;
    color: #666;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

@media (max-width: 767px) {
    html {
        font-size: 15px;
    }
}

.MuiPickersToolbar-root,
.MuiDTTabs-tabs,
.MuiDay-daySelected,
.MuiPickersDay-root.Mui-selected,
.MuiClockPointer-root,
.MuiClockPointer-noPoint,
.MuiTabs-flexContainer,
.MuiClock-pin {
    background-color: #fc7c00 !important;
}

.MuiPickersToolbar-root span {
    color: white !important;
}

.MuiButtonBase-root > .MuiSvgIcon-root ~[data-testId="CalendarIcon"]{
    fill: white;
}

.MuiClockPointer-thumb {
    border: 16px solid #fc7c00 !important;
    background-color: white !important;
}

.MuiButton-textPrimary {
    color: #fc7c00 !important;
}

.ReactModal__DrawerContent {
    transform: translateX(-100%);
    transition: transform 0.5s ease;
}

.ReactModal__DrawerContent--after-open {
    transform: translateX(0);
}

.ReactModal__DrawerContent--before-close {
    transform: translateX(-100%);
}

.ReactModal__Content {
    max-width: max(600px, 96vw);
}

.select-search__select {
    z-index: 4;
}
